import React, {useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import ReactGA from "react-ga";
import "./ProjectCard.css";
import ProjectModal from "./ProjectModal";

const ProjectCard = ({color, title, subtitle, description, shortDescription, image, link, skills}) => {
  const [open, setOpen] = useState(false);
  function trackButtonClick(label) {
    if (open) return;
    ReactGA.event({
      category: 'ACCESS',
      action: 'BUTTON_CLICK',
      label: `${label} project card`,
    })
    setOpen(true)
  }

  function handleModal(state) {
    console.log(state)
    setOpen(state)
  }

  return (
      <div
        onClick={() => trackButtonClick(title)}
        className="projectCard"
        style={{backgroundColor: color, cursor: link && "pointer"}}
      >
        <Grid
          sx={{
            backgroundColor: color,
          }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography sx={{ pb: 2, color: "#fff" }} variant="h4">{title}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="body1" sx={{ color: "fff" }} >{shortDescription || description}</Typography>
          </Grid>
          {
            /**
             *           <Grid sx={{ position: 'absolute', bottom: 20 }}>
             *             <Button  variant="contained" size="large" startIcon={<GitHubIcon />}>
             *               Github Project
             *             </Button>
             *           </Grid>
             */
          }
        </Grid>
        <ProjectModal open={open} setOpen={handleModal} title={title} subtitle={subtitle} description={description} skills={skills} image={image} link={link} color={color} />
      </div>
  );
};

export default ProjectCard;