import * as React from 'react';
import ReactGA from 'react-ga';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Typography, Button } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import Slider from 'react-slick';
import styled from 'styled-components';
import ProjectCard from "./ProjectCard";

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToShow: 4,
  slidesToScroll: 1,
  draggable: true,
  centerMode: true,
  centerPadding: '4 0px',
  swipeToSlide: true,
  arrows: false,
  accessibility: false,
  dotsClass: "MosaiqueDot",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        centerPadding: '15px',
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 900,
      settings: {
        speed: 500,
        slidesToShow: 1,
        centerPadding: '15px',
      },
    },
  ],
};

export default function SimpleSlider() {

  const projects = [
    {
      // Reddit orange
      color: "#ff4500",
      title: 'Redditech',
      subtitle: "Reddit mobile application",
      shortDescription: "A Reddit phone application that allows you to browse Reddit's content and interact with it using it's API.",
      description: (
        <div>
          <b>I. Introduction</b><br/>
          Redditech is a Reddit mobile application that allows you to browse Reddit's content and interact with it using it's API.<br/><br/>
          At the time, the reddit API was free, I have implemented a <b>login system</b> that allows you to log in to your Reddit account and interact with the content.<br/>
          The application is functional and allows you to <b>browse the content of the subreddits</b>, upvote, downvote as well as <b>looking and updating profile</b><br/><br/>
          <b>II. My involvement</b><br/>
          I was in charge with one student of the <b>entire project</b>, it lasted 1 month during my 3rd year at <a href={"https://epitech.eu"}>Epitech</a>.<br/><br/>
          <b>React Native</b> and <b>Redux</b> were used for the developement of the application<br/>
        </div>
      ),
      link: "https://github.com/Autosuffisant/Redditech",
      image: "redditech.png",
      skills: [
        {
          name: "React Native",
          color: "#61dafb"
        },
        {
          name: "Redux",
          color: "#764abc"
        },
        {
          name: "Javascript",
          // Javascript yellow but darker for contrast
          color: "#F1B42F"
        },
        {
          name: "Reddit API",
          color: "#ff4500"
        },
      ]
    },
    {
      color: "#568203",
      title: "Car Racing",
      subtitle: "Reinforcement learning 2D car control",
      link: "https://github.com/nathan-hoche/CarRacing",
      shortDescription: "A reinforcement learning project that uses the OpenAI Gym library to train a car to drive on a track with multiple algorithms",
      description: (
        <div>
          <b>I. Introduction</b><br/>
          Created during my year at <a href={"https://kent.ac.uk"}>University of Kent</a>, this project is a reinforcement learning project that uses the <a href={"https://gym.openai.com/"}>OpenAI Gym</a> library to train a car to drive on a track with multiple reinforcement learning algorithms.<br/><br/>
          With four other members, we created a whole test architecture to train and test the algorithms. The test framework is composed of four fully functional algorithms and a test suite that allows us to test the algorithms on multiple tracks.<br/><br/>
          <b>II. My involvement</b><br/>
          I was in charge of the <b>DDPG algorithm</b> and the test suite.<br/>
          I also implemented a <b>genetic algorithm</b> to train the car.<br/><br/>
          We used Python with <b>PyTorch, Keras and Tensorflow</b> to create the algorithms.<br/><br/>
        </div>
      ),
      skills: [
        {
          name: "Python",
          src: "python.svg",
          color: "#3776ab"
        },
        {
          name: "Keras",
          src: "keras.svg",
          color: "#d00000",
        },
        {
          name: "Tensorflow",
          src: "tensorflow.svg",
          color: "#ff6f00",
        },
        {
          name: "PyTorch",
          src: "pytorch.svg",
          color: "#ee4c2c",
        },
        {
          name: "OpenAI Gym",
          src: "openai-gym.png",
          color: "#00d8ff",
        },
        {
          name: "Matplotlib",
          src: "matplotlib.svg",
          color: "#3776ab",
        },
      ],
      image: "carRacing.pbm",
    },
    {
      color: "#005",
      title: 'Domestia',
      subtitle: 'On demand services at home',
      shortDescription: "A platform that allows your to book services at home such as cleaning, gardening, babysitting and more",
      // description: "Introduction\nDesigned and created as part of the EPITECH Innovative projects, Domestia is a platform that allows you to book services at home such as cleaning, gardening, babysitting and more.\n\nMy involvement\nI was in charge of the front-end development of the platform. I used React.js to create the front-end.\n\nThis project is still in development until February 2024\n",
      description: (
        <div>
          <b>I. Introduction</b><br/>
          Designed and created as part of the <a href={"https://www.epitech.eu/en/innovation-hub/"}>EPITECH Innovative projects</a>, Domestia is a service divided in <b>two functionalities</b>:
          <ul>
            <li style={{ color: "#000" }}>As a customer you can book services at home such as cleaning, gardening, babysitting and more</li>
            <li style={{ color: "#000" }}>As a professional, you can offer your services to customers using Domestia Pro</li>
          </ul>
          Each part has its own web and mobile application.<br/><br/>
          <b>II. My involvement</b><br/>
          I was in charge of the front-end development of the web platform.<br/>
          I used React.js to create the front-end and have worked on every single aspect of the application from the design to the implementation and maintenance<br/><br/>
          The work was done in collaboration with 6 other students and is is still in development until February 2024<br/>
        </div>
      ),
      link: "https://domestia.fr",
      image: "domestia.png",
      skills: [
        {
          name: "React.js",
          src: "react.png",
          color: "#61DBFB"
        },
        {
          name: "Typescript",
          src: "typescript.png",
          color: "#007ACC"
        },
        {
          name: "HTML 5",
          src: "html-5.svg",
          color: "#E34F26"
        },
        {
          name: "CSS 3",
          src: "css-3.svg",
          color: "#1572B6"
        },
        {
          name: "Redux",
          src: "redux.png",
          color: "#764ABC"
        },
        {
          name: "MUI",
          src: "mui.png",
          color: "#0081CB",
        },
        {
          name: "Figma",
          src: "figma.png",
          color: "#F24E1E"
        },
      ]
    },
    {
      // bright purple
      color: "#8338ec",
      title: 'Dashboard',
      subtitle: 'A widget management dashboard',
      shortDescription: "A widget management dashboard capable of interacting with multiple famous APIs such as Spotify, Github and Google.",
      description: (
        <div>
          <b>I. Introduction</b><br/>
          Created during my third year at <a href={"https://epitech.eu"}>EPITECH</a>, this project is a widget management dashboard capable of interacting with multiple famous APIs such as Spotify, Github and Google.<br/><br/>
          The dashboard is composed of multiple widgets that can be added, removed and moved around the inferace.
          The project was made using <b>React.js</b> for the front-end and <b>Node.js</b> for the back-end with <b>MongoDB</b> as the database.<br/><br/>
          <b>II. My involvement</b><br/>
          I was in charge of the full-stack <b>development</b> of the project. I designed the architecture of the application and implemented the front-end and back-end in less than a month<br/><br/>
        </div>
      ),
      link: "https://github.com/autosuffisant/dashboard",
      image: "dashboard.png",
      skills: [
        {
          name: "React.js",
          src: "react.png",
          color: "#61DBFB"
        },
        {
          name: "Typescript",
          src: "typescript.png",
          color: "#007ACC"
        },
        {
          name: "Node.js",
          src: "nodejs.png",
          color: "#339933",
        },
        {
          name: "MongoDB",
          src: "mongodb.png",
          color: "#47A248",
        },
        {
          name: "HTML 5",
          src: "html-5.svg",
          color: "#E34F26"
        },
        {
          name: "CSS 3",
          src: "css-3.svg",
          color: "#1572B6"
        }
        ]
    },
    {
      // C++ blue
      color: "#00599C",
      title: 'Indie Studio',
      subtitle: 'A 3D game engine and a Bomberman-like game',
      shortDescription: "A 3D game engine and a Bomberman-like game made in C++ using the raylib graphical library",
      description: (
        <div>
          <b>I. Introduction</b><br/>
          Created during my second year at <a href={"https://epitech.eu"}>EPITECH</a>, this project is a 3D game engine and a Bomberman-like game made in C++ using the raylib graphical library.<br/><br/>
          The game engine is capable of rendering 3D models, playing sounds and music, handling collisions and game scenes<br/><br/>
          The project was made in a group of 4 students<br/><br/>
          <b>II. My involvement</b><br/>
          I was in charge of the <b>development</b> of the game engine and the game itself. I designed parts of the architecture of the application and implemented the game with the engine<br/><br/>
        </div>
      ),
      link: null,
      image: "indieStudio.png",
      skills: [
        {
          name: "C++",
          src: "cplusplus.png",
          color: "#00599C"
        },
        {
          name: "Raylib",
          src: "raylib.png",
          color: "#FF3300"
        },
        {
          name: "Object Oriented Programming",
          src: "oop.png",
          color: "#01796F",
        },
        {
          name: "Entity Component System",
          src: "ecs.png",
          color: "#6F2DA8"
        }
      ]
    }
  ];

  function trackButtonClick(label) {
    ReactGA.event({
      category: 'ACCESS',
      action: 'BUTTON_CLICK',
      label,
    })
  }

  return (
    <Grid>
      <Slider {...settings}>
          {
            projects.map((project, index) => (
              <ProjectCard key={project.title + project.subtitle} title={project.title} subtitle={project.subtitle} description={project.description} shortDescription={project.shortDescription} color={project.color} image={project.image} link={project.link} skills={project.skills}/>
            ))
          }
      </Slider>
    </Grid>
  );
}