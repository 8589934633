import {Grid, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import {FaGithub, FaLinkedin} from "react-icons/fa";
import React from "react";

const Footer = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: !mobile && -5, backgroundColor: '#031A4C' }}
    >
      <Grid item>
        <Typography
          variant="h5"
          sx={{ color: '#fff', fontFamily: 'quicksand', mt: 2 }}
        >
          Contact
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          sx={{ color: '#fff', fontFamily: 'quicksand', mt: 2 }}
        >
          gabriel.huguenin-dumittan@epitech.eu
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          sx={{ color: '#fff', fontFamily: 'quicksand', mt: 2, mb: 2 }}
        >
          +33 7 77 14 02 50
        </Typography>
      </Grid>
      <Grid item>
        <Link href="https://www.linkedin.com/in/gabriel-huguenin-dumittan/" target="_blank" rel="noopener">
          <FaLinkedin alt="Linkedin" style={{ color: '#ddd', marginRight: 10, fontSize: 25, marginBottom: 10 }} />
        </Link>
        <Link href="https://github.com/Autosuffisant" target="_blank" rel="noopener">
          <FaGithub alt="Github" style={{ color: '#ddd', fontSize: 25, marginBottom: 10  }} />
        </Link>
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          sx={{ color: '#fff', fontFamily: 'quicksand', mb: 2 }}
        >
          Powered by Prism
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Footer;