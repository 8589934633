import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { ThemeProvider } from '@emotion/react';
import { createTheme, alpha } from '@mui/material/styles'
import { Grid, CssBaseline, Fade, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HiOutlineChevronDoubleDown } from 'react-icons/hi';
import '@fontsource/quicksand'
import NavBar from './NavBar';
import Mosaique from './Mosaique';
import './App.css';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import Services from "./Services";
import Footer from './Footer';

ReactGA.initialize('UA-206885370-1');

const AppTheme = createTheme({
  palette: {
    Typography: {
      fontFamily: [
        'quicksand',
        'Roboto',
      ].join(','),
    },
    primary: {
      main: '#0a467a',
    },
    secondary: {
      main: '#6F76D8',
    },
    text: {
      main: '#fff',
    },
    background: {
      default: '#8A8FD0x'
    }
  },
})


const useStyles = makeStyles((theme) => ({
  background: {
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: 'url(background.webp)',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
  },
  link: {
    textDecoration: "underline transparent",
    transition: 'color 1s, text-decoration-color 1s',
    "&:hover": {
        color: "#fff",
        textDecoration: "underline #fff"
    },
  },
  downArrow: {
    position: 'absolute',
    bottom: 20,
    color: '#eee',
    opacity: 0.5,
    fontSize: 30,
    transition: 'all 0.5s',
    "&:hover": {
      cursor: 'pointer',
      color: "#eee",
      opacity: 1,
    },
  },
  noSelect: {
    WebkitTouchCallout: 'none', /* iOS Safari */
    WebkitUserSelect: 'none',   /* Safari */
    KhtmlUserSelect: 'none',    /* Konqueror HTML */
    MozUserSelect: 'none',      /* Old Firefox */
    msUserSelect: 'none',       /* IE/Edge */
    userSelect: 'none',         /* Non prefixed, standard */
  },
  bottomWaves: {
    width: '100%',
    aspectRatio: 900/300,
    backgroundImage: "url(layered-waves.svg)",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }
}));

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  const goToProjects = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    })
  }

  return (
    <ThemeProvider theme={AppTheme}>
      <CssBaseline>
        <Grid>
          <NavBar />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.background}
          >
            <Fade in timeout={3000}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h1" className={classes.noSelect} sx={{ fontSize: mobile && 60, fontFamily: 'quicksand', color: '#7dadaa' }}>Gabriel</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h2" className={classes.noSelect} sx={{ fontSize: mobile && 40, fontFamily: 'quicksand', color: '#7dadaa' }}>Huguenin-Dumittan</Typography>
                </Grid>
                <Grid item container direction="row" justifyContent="center">
                  <Typography variant="h4" sx={{ fontSize: mobile && 25, fontFamily: 'quicksand', color: '#7dadaa', pt: 2 }}>
                    Student Developer at&nbsp;
                  </Typography>
                  <Link href="https://epitech.eu" target="_blank" rel="noopener">
                    <Typography
                      variant="h4"
                      className={classes.link}
                      sx={{
                        fontSize: mobile && 25,
                        fontFamily: 'quicksand',
                        color: alpha('#ddd', 0.9),
                        pt: 2,
                      }}
                    >
                      Epitech
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Fade>
            <HiOutlineChevronDoubleDown onClick={() => goToProjects()} className={classes.downArrow} sx={{ position: 'absolute', bottom: 10, fontSize: 25, color: alpha('#eee', 0.5) }} />
          </Grid>
          <Services />
          <Grid container justifyContent="center" alignContent='center' sx={{ mt: 5, mb: 5 }}>
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h3"
                sx={{
                  fontFamily: 'quicksand',
                  fontWeight: 500,
                  color: '#000',
                  textUnderlineOffset: 15,
                  textDecorationThickness: 2.5,
                  textAlign: 'center',
                }}
                className={classes.noSelect}
              >
                My projects
              </Typography>
            </Grid>
          </Grid>
          <Mosaique />
          <Grid sx={{ mt: !mobile && -5 }} className={classes.bottomWaves}/>
          <Footer />
        </Grid>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
