import * as React from 'react';
import ReactGA from 'react-ga';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Button, ButtonBase, Grid, IconButton} from '@mui/material';
import {FaLinkedin, FaGithub} from 'react-icons/fa';
import useMediaQuery from '@mui/material/useMediaQuery';
import Resume from './documents/Resume.pdf';
import "./Logo.css"
import {useTheme} from '@emotion/react';
import HideOnScroll from './HideOnScroll';
import {makeStyles} from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  noSelect: {
    WebkitTouchCallout: 'none', /* iOS Safari */
    WebkitUserSelect: 'none',   /* Safari */
    KhtmlUserSelect: 'none',    /* Konqueror HTML */
    MozUserSelect: 'none',      /* Old Firefox */
    msUserSelect: 'none',       /* IE/Edge */
    userSelect: 'none',         /* Non prefixed, standard */
  },
  hoverFade: {
    color: '#ccc',
    fontWeight: 100,
    borderBottom: '2px solid',
    borderBottomColor: 'transparent',
    transition: 'all 0.5s',
    "&:hover": {
      fontWeight: 700,
      cursor: 'pointer',
      color: "#fff",
      opacity: 1,
    },
  },
  borderHover: {
    borderBottom: '2px solid',
    borderBottomColor: 'transparent',
    transition: 'all 0.5s',
    "&:hover": {
      borderBottom: '1px solid',
      borderBottomColor: '#fff',
      cursor: 'pointer',
      opacity: 1,
    },
  }
}));


export default function NavBar() {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Track position of scroll and make navbar less transparent
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // 95% of window height
  const isScrolled = scrollPosition > window.innerHeight * 0.90;

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  function goTo(link) {
    window.open(link);
  }

  function openPDF() {
    ReactGA.event({
      category: 'ACCESS',
      action: 'BUTTON_CLICK',
      label: 'CV',
    })
    window.open(Resume)
  }

  return !isScrolled && (
    <HideOnScroll>
      <AppBar position="fixed" sx={{ width: "100%", backgroundColor: "transparent"}}>
        <Toolbar>
          <Grid
            sx={{ opacity: 1 }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <img
                src="LogoPrism.webp"
                className="WebLogo"
                alt="Logo"
                onClick={backToTop}
              />
            </Grid>
            {
              mobile ||
              <Grid item>
                <Typography
                  variant="h6"
                  sx={{fontFamily: 'quicksand', ml: 2}}
                  className={classes.noSelect}
                >
                  Welcome
                </Typography>
              </Grid>
            }
            <Grid item>
              <Grid container justifyContent="center" alignItems="space-between" sx={{gap: 1}}>
                <Typography
                  variant="body1"
                  sx={{fontFamily: 'quicksand', fontWeight: 700}}
                  className={classes.hoverFade}
                  onClick={() => openPDF()}
                >
                  CV
                </Typography>
                <FaLinkedin
                  alt="Linkedin"
                  style={{fontSize: 22}}
                  className={classes.hoverFade}
                  onClick={() => goTo("https://www.linkedin.com/in/gabriel-huguenin-dumittan")}
                />
                <FaGithub
                  alt="Github"
                  style={{fontSize: 22}}
                  className={classes.hoverFade}
                  onClick={() => goTo("https://www.github.com/autosuffisant")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}