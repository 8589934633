import {
  Button, Card, Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Fab,
  Grid, Icon, Link, Modal, Paper, Tooltip,
  useTheme
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from "@mui/styles";
import {GrClose} from "react-icons/gr";
import { IconContext } from "react-icons";

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "none",
  },
  card: {
    '@media (max-width: 960px)': {
      height: '80vh',
    },
    position: 'relative',
    height: '70vh',
    maxHeight: "800px",
    width: "75vw",
    maxWidth: "1800px",
  },
  body: {
    height: '100%',
    position: 'relative',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
  },
  text: {
    color: '#000'
  },
  bold: {
    fontWeight: 900,
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  background: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
  }
}));

/**
 * @description Modal component for displaying project details when a project card is clicked
 * @param color {string} - Color of the project
 * @param open {boolean} - Whether the modal is open or not
 * @param setOpen {function} - Function to set the open state of the modal
 * @param title {string} - Title of the project
 * @param description {string} - Description of the project
 * @param link {string} - Link to the project
 * @param preview {string} - Link to the project preview
 * @param skills {Object[]} - Array of languages used in the project
 * @param image {string} - Image of the project
 */
const ProjectModal = ({ open, setOpen, color, title, subtitle, description, link, preview, skills, image }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobile, setMobile] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 960);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
      <Modal
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
        className={classes.dialog}
      >
        <Paper className={classes.card}>
          <Fab
            color="default"
            aria-label="close"
            sx={{ position: 'absolute', height: 36, width: 36, top: -18, right: -18, backgroundColor: '#fff' }}
            onClick={() => setOpen(false)}
          >
            <GrClose size={24} />
          </Fab>
          <Grid container xs={12} sx={{ height: "100%" }}>
            <Grid item xs={12} md={6} className={classes.body}>
              <Grid container display="block" sx={{ padding: "32px 32px", height: "calc(100% - 64px)", overflow: "scroll" }}>
                {
                  !link ? (
                    <Tooltip title={"Project code is not public or available"} placement="right">
                      <Link href={link} target="_blank" rel="noopener noreferrer" underline="always" sx={{ fontSize: "1.5rem", color: color }}>
                        {title}
                      </Link>
                    </Tooltip>
                  ) : (
                    <Link href={link} target="_blank" rel="noopener noreferrer" underline="always" sx={{ fontSize: "1.5rem", color: color }}>
                      {title}
                    </Link>
                  )
                }
                <Typography variant="h6" sx={{ fontSize: 16 }}>{subtitle}</Typography>
                <Typography variant="body1" sx={{ pt: "1em" }} className={`${classes.text} ${classes.bold}`}>Description:</Typography>
                <Typography variant="body1" sx={{ fontSize: 14, pt: "1em" }} className={classes.text}>
                  {description}
                </Typography>
              </Grid>
              <Paper sx={{ left: 0, bottom: 0, width: "100%" }} elevation={10}>
                <Grid sx={{ width: "100%", height: "64px", p: "8px 28px", whiteSpace: "nowrap", overflowX: "scroll", overflowY: "hidden", gap: "16px" }}>
                  {
                    skills && skills.map((skill, index) => (
                      <Grid item key={index} sx={{ display: "inline-block", p: "8px 4px"}}>
                        <Chip label={skill.name} variant="filled" sx={{ backgroundColor: skill.color ? skill.color : color, color: "#fff" }} />
                      </Grid>
                    ))
                  }
                </Grid>
              </Paper>
            </Grid>
            <Grid xs={12} md={6} className={classes.background} sx={{display: {xs: "none", md: "flex"}}}>
              <img src={image} alt="Project" style={{width: "auto", height: "auto"}}/>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
  );
};

export default ProjectModal;