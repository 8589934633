import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import {ThemeProvider} from '@emotion/react';
import {createTheme, alpha} from '@mui/material/styles'
import {Grid, CssBaseline, Fade, Link, Typography, useMediaQuery, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {HiOutlineChevronDoubleDown} from 'react-icons/hi';
import '@fontsource/quicksand'
import NavBar from './NavBar';
import Mosaique from './Mosaique';
import './App.css';
import {FaGithub, FaLinkedin} from 'react-icons/fa';
import ServiceCard from "./ServiceCard";

ReactGA.initialize('UA-206885370-1');

const AppTheme = createTheme({
  palette: {
    Typography: {
      fontFamily: [
        'quicksand',
        'Roboto',
      ].join(','),
    },
    primary: {
      main: '#0a467a',
    },
    secondary: {
      main: '#6F76D8',
    },
    text: {
      main: '#fff',
    },
    background: {
      default: '#8A8FD0x'
    }
  },
})


const useStyles = makeStyles((theme) => ({
  background: {
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: 'url(background.webp)',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
  },
  link: {
    textDecoration: "underline transparent",
    transition: 'color 1s, text-decoration-color 1s',
    "&:hover": {
      color: "#fff",
      textDecoration: "underline #fff"
    },
  },
  downArrow: {
    position: 'absolute',
    bottom: 20,
    color: '#eee',
    opacity: 0.5,
    fontSize: 30,
    transition: 'all 0.5s',
    "&:hover": {
      cursor: 'pointer',
      color: "#eee",
      opacity: 1,
    },
  },
  noSelect: {
    WebkitTouchCallout: 'none', /* iOS Safari */
    WebkitUserSelect: 'none',   /* Safari */
    KhtmlUserSelect: 'none',    /* Konqueror HTML */
    MozUserSelect: 'none',      /* Old Firefox */
    msUserSelect: 'none',       /* IE/Edge */
    userSelect: 'none',         /* Non prefixed, standard */
  },
  bottomWaves: {
    width: '100%',
    aspectRatio: 900 / 300,
    backgroundImage: "url(layered-waves.svg)",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }
}));

function Services() {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const experiences = [
    {
      logo: 'pwc.png',
      color: "#D85604",
      title: "Business intelligence Trainee",
      company: "Freelance",
      city: "",
      country: "Luxembourg",
      from: "April",
      to: "September 2022",
      bulletPoints: [
        "Developed a  web application with React and C# .NET",
        "Modeled data with Microsoft SQL Server.",
        "Creation of dashboards with Power BI.",
        "Usage of ETL processes and tools."
      ],
      skills: [
        {
          name: "React",
          src: "react.png",
        },
        {
          name: "Html 5",
          src: "html-5.svg"
        },
        {
          name: "CSS 3",
          src: "css-3.svg"
        },
        {
          name: "Node.js",
          src: "nodejs.png"
        },
        {
          name: "C#",
          src: "csharp.png"
        },
        {
          name: ".NET",
          src: "dotnet.png"
        },
        {
          name: "Miscrosoft SQL Server",
          src: "microsoftsql.png"
        },
        {
          name: "Microsoft Azure",
          src: "microsoft-azure.svg"
        },
        {
          name: "Microsoft Power BI",
          src: "microsoft-power-bi.svg"
        }
      ]
    },
    {
      logo: "englishworld.jpeg",
      color: "#003166",
      title: "Full stack web developer",
      company: "Medical World",
      city: "Nancy, Metz",
      country: "France",
      from: "September 2021",
      to: "March 2022",
      bulletPoints: [
        "Programming of a web application features in React",
        "Implementation of features in an Express JS server",
        "UI Remake of an internal React application",
        "Migration of an SQL database to a mongoDB database",
      ],
      skills: [
        {
          name: "React",
          src: "react.png",
        },
        {
          name: "Html 5",
          src: "html-5.svg"
        },
        {
          name: "CSS 3",
          src: "css-3.svg"
        },
        {
          name: "Node.js",
          src: "nodejs.png"
        },
        {
          name: "MongoDB",
          src: "mongodb.svg"
        },

      ]
    },
    {
      logo: 'taker.png',
      color: "#00AEE7",
      title: "Project manager",
      company: "Taker",
      city: "Nancy, Paris",
      country: "France",
      from: "March 2021",
      to: "August 2022",
      bulletPoints: [
        "Project management and supervision of small development teams",
        'Development of websites and mobile applications',
        "Prospecting and active search for customers",
        "Member of the partnership and events committee",
      ],
      skills: [
        {
          name: "React and React Native",
          src: "react.png",
        },
        {
          name: "Html 5",
          src: "html-5.svg"
        },
        {
          name: "CSS 3",
          src: "css-3.svg"
        },
        {
          name: "Wordpress",
          src: "wordpress.svg"
        },
        {
          name: "Node.js",
          src: "nodejs.png"
        },
        {
          name: "MongoDB",
          src: "mongodb.svg"
        },
      ]
    },
    {
      logo: "englishworld.jpeg",
      color: "#dd3e52",
      title: "Web front-end intern",
      company: "English World",
      city: "Nancy",
      country: "France",
      from: "September",
      to: "December 2020",
      bulletPoints: [
        "Programming of web interfaces in HTML5, CSS3",
        "Refactoring of an online platform with React",
        "Implementation of a Web server with Express JS and MongoDB",
        "Creation of advertising assets with Photoshop and Illustrator"
      ],
      skills: [
        {
          name: "React",
          src: "react.png",
        },
        {
          name: "Html 5",
          src: "html-5.svg"
        },
        {
          name: "CSS 3",
          src: "css-3.svg"
        },
        {
          name: "Adobe Photoshop",
          src: "adobe-photoshop.svg"
        },
        {
          name: "Adobe Illustrator",
          src: "adobe-illustrator.svg"
        },
        {
          name: "Node.js",
          src: "nodejs.png"
        },
        {
          name: "MongoDB",
          src: "mongodb.svg"
        },
      ]
    }
  ];

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  const goToProjects = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    })
  }

  function checkCardLayout(index) {
    if (!mobile && index % 2) {
      return true;
    }
    return false
  }

  return (
    <Grid container justifyContent="center" alignContent='center' sx={{ p: 4, width: "100%" }}>
      <Grid item>
        <Typography
          variant="h3"
          sx={{
            fontFamily: 'quicksand',
            fontWeight: 500,
            color: '#000',
            textUnderlineOffset: 15,
            textDecorationThickness: 2.5,
            textAlign: 'center',
            mb: 5,
          }}
          className={classes.noSelect}
        >
          My experiences
        </Typography>
      </Grid>
      <Grid container alignContent='center' spacing={mobile ? 1 : 4}>
        {
          experiences.map((service, index) => (
            <>
              {
                checkCardLayout(index) && (
                  <Grid item xs={4}>
                    <img src={""} alt=""/>
                  </Grid>
                )
              }
              <Grid item xs={12} md={8} key={service.title + service.from.toString() + service.to.toString()} sx={{display: 'flex', alignItems: 'center'}}>
                <ServiceCard
                  logo={service.logo}
                  color={service.color}
                  title={service.title}
                  company={service.company}
                  city={service.city}
                  country={service.country}
                  from={service.from}
                  to={service.to}
                  bulletPoints={service.bulletPoints}
                  skills={service.skills}
                />
              </Grid>
              {
                !checkCardLayout(index) && (
                  <Grid item xs={4}>
                    <img src={""} alt=""/>
                  </Grid>
                )
              }
            </>
          ))
        }
      </Grid>
    </Grid>
  );
}

export default Services;
