import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import {ThemeProvider} from '@emotion/react';
import {createTheme, alpha} from '@mui/material/styles'
import {
  Grid,
  CssBaseline,
  Fade,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  Button,
  Collapse, Chip, Tooltip, Icon
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {HiOutlineChevronDoubleDown} from 'react-icons/hi';
import '@fontsource/quicksand'
import NavBar from './NavBar';
import Mosaique from './Mosaique';
import './App.css';
import './ServiceCard.css';
import {FaGithub, FaLinkedin} from 'react-icons/fa';
import {CgChevronDown} from "react-icons/cg";

ReactGA.initialize('UA-206885370-1');

const AppTheme = createTheme({
  palette: {
    Typography: {
      fontFamily: [
        'quicksand',
        'Roboto',
      ].join(','),
    },
    primary: {
      main: '#0a467a',
    },
    secondary: {
      main: '#6F76D8',
    },
    text: {
      main: '#fff',
    },
    background: {
      default: '#8A8FD0x'
    }
  },
})


const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  background: {
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: 'url(background.webp)',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
  },
  link: {
    textDecoration: "underline transparent",
    transition: 'color 1s, text-decoration-color 1s',
    "&:hover": {
      color: "#fff",
      textDecoration: "underline #fff"
    },
  },
  downArrow: {
    position: 'absolute',
    bottom: 20,
    color: '#eee',
    opacity: 0.5,
    fontSize: 30,
    transition: 'all 0.5s',
    "&:hover": {
      cursor: 'pointer',
      color: "#eee",
      opacity: 1,
    },
  },
  noSelect: {
    WebkitTouchCallout: 'none', /* iOS Safari */
    WebkitUserSelect: 'none',   /* Safari */
    KhtmlUserSelect: 'none',    /* Konqueror HTML */
    MozUserSelect: 'none',      /* Old Firefox */
    msUserSelect: 'none',       /* IE/Edge */
    userSelect: 'none',         /* Non prefixed, standard */
  },
  bottomWaves: {
    width: '100%',
    aspectRatio: 900 / 300,
    backgroundImage: "url(layered-waves.svg)",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }
}));

function ServiceCard({logo, color, title, company, city, country, from, to, bulletPoints, skills}) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [collapsed, setCollapsed] = React.useState(true);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  // Card of past work experience, with a title, company, location, dates, and bullet points
  return (
    <Grid item>
      <Card
        variant="outlined"
        onClick={() => setCollapsed(current => !current)}
        sx={{
          height: '100%',
          padding: 2,
          borderColor: color,
          borderWidth: 2,
          cursor: "pointer",
          borderRadius: ".75em"
        }}
      >
        <Grid container>
          <Grid container item xs={12} alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <img src={logo} alt={`${company} icon`} style={{height: "7vh"}}/>
                </Grid>
                <Grid item>
                  <Grid container item direction="column">
                    <Grid item>
                      <Typography variant="h5" className={`${classes.bold} ${classes.textAlignLeft}`}
                                  sx={{color, fontSize: mobile ? 12 : 26}}>
                        {title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" className={`${classes.textAlignLeft}`}
                                  sx={{fontSize: mobile ? 11 : 20, fontStyle: "italic"}}>
                        {city}{city && ", "}
                        <Typography variant="subtitle1" className={`${classes.textAlignLeft}`} sx={{
                          fontSize: mobile ? 11 : 20,
                          fontWeight: 400,
                          display: "inline",
                          fontStyle: "normal"
                        }}>
                          {country}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" className={`${classes.textAlignLeft}`}
                                  sx={{fontSize: mobile ? 10 : 18}}>
                        {from && from.toString()} - {to && to.toString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Icon
              component={CgChevronDown}
              sx={{
                color: color,
                fontSize: mobile ? 20 : 40,
                transition: 'all 0.5s',
                transform: collapsed ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            />
          </Grid>
          <Collapse in={!collapsed}>
            <Grid container xs={12} spacing={2} sx={{paddingTop: ".5em"}}>
              <Grid item xs={12} md={6}>
                <Grid container sx={{textAlign: "left", gap: ".5em"}}>
                  <Grid item xs={12}>
                    <Typography variant="h6" className={`${classes.bold}`} sx={{color, fontSize: mobile ? 14 : 20}}>
                      My involvement
                    </Typography>
                  </Grid>
                  {bulletPoints && bulletPoints.map((point) => (
                    <Typography sx={{fontSize: mobile ? 12 : 16, color: "#000"}}>• {point}</Typography>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container justifyContent="center" sx={{gap: "1em"}}>
                  <Grid item xs={12}>
                    <Typography variant="h6" className={`${classes.bold}`} sx={{color, fontSize: mobile ? 14 : 20}}>
                      Skills
                    </Typography>
                  </Grid>
                  {skills && skills.map((skill) => (
                    <Tooltip title={skill.name} placement="top">
                      <Grid item>
                        <img src={skill.src} alt={"skill icon"} style={{height: "8vh"}}/>
                      </Grid>
                    </Tooltip>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Card>
    </Grid>
  );
}

export default ServiceCard;
